import React from "react";

function ChurritoFi() {
  return (
    <svg
      width="138"
      height="32"
      viewBox="0 0 786 182"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_309_29)">
        <path
          d="M94.788 135.421C82.9605 126.822 69.2485 119.326 54.0426 113.137C28.2258 102.626 0.422668 105.936 0.422668 105.936L18.1073 177.012C18.6959 179.377 20.8237 181.034 23.2571 181.034H125.834C128.765 181.034 131.142 178.658 131.142 175.728C131.142 174.613 109.626 146.203 94.788 135.426V135.421Z"
          fill="#35D07F"
        />
        <path
          d="M86.0334 117.046C91.1885 120.065 96.1933 123.333 101.03 126.839C109.813 133.22 119.807 144.24 126.649 152.364C129.45 155.69 132.234 159.141 134.645 162.275L148.668 105.935C148.668 105.935 109.445 105.766 86.0277 117.046H86.0334Z"
          fill="#85E2B2"
        />
        <path
          d="M74.5455 110.806L81.4213 107.486C84.9237 105.821 88.5349 104.396 92.2301 103.22V12.3776C92.2285 11.3495 91.9282 10.3439 91.3655 9.48323C90.8029 8.62254 90.0023 7.94377 89.061 7.52944L76.5771 0.452354C75.8976 0.152177 75.1626 -0.00193084 74.4198 1.82602e-05C73.6769 0.00196736 72.9427 0.15993 72.2648 0.463668L60.0016 7.5351C59.068 7.9531 58.2752 8.63214 57.719 9.49036C57.1627 10.3486 56.8666 11.3493 56.8665 12.372V102.847L58.0549 103.316C63.7536 105.636 69.2599 108.142 74.5511 110.812L74.5455 110.806Z"
          fill="#FBCC5C"
        />
        <path
          d="M124.062 40.9009C124.062 38.4118 122.336 36.2621 119.909 35.719L107.527 32.1833C106.77 32.0152 105.986 32.0152 105.229 32.1833L102.841 32.8678V100.335C109.843 98.7634 116.928 97.5886 124.062 96.8161V40.8953V40.9009ZM46.25 32.8678L43.8618 32.1889C43.1052 32.0209 42.3209 32.0209 41.5642 32.1889L29.1822 35.719C28.0038 35.9804 26.95 36.6358 26.1948 37.577C25.4395 38.5182 25.0281 39.6887 25.0284 40.8953V95.5037C31.5816 96.1373 38.8366 97.3027 46.25 99.3336V32.8678V32.8678Z"
          fill="#E1B752"
        />
        <path
          d="M134.673 72.0435V95.8601C138.931 95.5644 143.196 95.389 147.463 95.3339C145.443 88.1211 141.17 80.3425 134.673 72.0435ZM14.4119 94.8701V71.9247C7.94925 80.1898 3.68796 87.9514 1.65634 95.1755C5.90035 94.8591 10.1576 94.7572 14.4119 94.8701V94.8701Z"
          fill="#C8A349"
        />
        <path
          d="M239.219 95.382C239.219 87.9598 240.849 81.488 244.107 75.9666C247.456 70.3547 251.801 65.9647 257.141 62.7967C262.482 59.6287 268.275 58.0447 274.52 58.0447C279.046 58.0447 283.164 58.633 286.876 59.8097C290.677 60.9864 294.117 62.6609 297.194 64.8333C300.272 66.9151 302.897 69.359 305.069 72.165V56.9585C301.086 53.4284 296.742 50.8035 292.035 49.0837C287.328 47.3639 281.49 46.504 274.52 46.504C267.913 46.504 261.712 47.726 255.919 50.1699C250.217 52.5233 245.193 55.9176 240.849 60.3528C236.504 64.6975 233.11 69.8569 230.666 75.8308C228.222 81.8048 227 88.3219 227 95.382C227 102.442 228.222 108.959 230.666 114.933C233.11 120.907 236.504 126.112 240.849 130.547C245.193 134.892 250.217 138.286 255.919 140.73C261.712 143.083 267.913 144.26 274.52 144.26C281.49 144.26 287.328 143.4 292.035 141.68C296.742 139.961 301.086 137.336 305.069 133.806V118.599C302.897 121.405 300.272 123.894 297.194 126.067C294.117 128.148 290.677 129.778 286.876 130.954C283.164 132.131 279.046 132.719 274.52 132.719C268.275 132.719 262.482 131.135 257.141 127.967C251.801 124.799 247.456 120.409 244.107 114.797C240.849 109.186 239.219 102.714 239.219 95.382Z"
          fill="black"
        />
        <path
          d="M335.559 37H324.698V142.902H335.559V37ZM364.75 104.886V142.902H375.612V103.528C375.612 98.0975 374.707 93.5717 372.897 89.9511C371.177 86.3305 368.688 83.6151 365.429 81.8048C362.261 79.9945 358.505 79.0894 354.16 79.0894C349.815 79.0894 345.969 80.2208 342.62 82.4837C339.361 84.7465 336.781 87.824 334.881 91.7162C332.98 95.5178 332.029 99.9078 332.029 104.886H335.559C335.559 101.899 336.193 99.2289 337.46 96.8755C338.818 94.5221 340.673 92.6666 343.027 91.3089C345.471 89.9511 348.277 89.2723 351.445 89.2723C355.88 89.2723 359.184 90.5847 361.356 93.2097C363.619 95.8346 364.75 99.7267 364.75 104.886Z"
          fill="black"
        />
        <path
          d="M406.893 118.463V80.4471H396.031V119.821C396.031 127.243 397.977 133.172 401.869 137.607C405.761 142.042 410.966 144.26 417.483 144.26C421.647 144.26 425.267 143.355 428.345 141.545C431.422 139.644 434.002 136.838 436.084 133.127V142.902H446.946V80.4471H436.084V118.463C436.084 121.541 435.405 124.256 434.047 126.61C432.78 128.963 430.924 130.819 428.481 132.176C426.127 133.443 423.366 134.077 420.198 134.077C415.854 134.077 412.55 132.719 410.287 130.004C408.024 127.288 406.893 123.442 406.893 118.463Z"
          fill="black"
        />
        <path
          d="M478.226 80.4471H467.364V142.902H478.226V80.4471ZM497.099 92.395L503.073 83.4341C501.443 81.7143 499.633 80.5829 497.642 80.0398C495.741 79.4062 493.659 79.0894 491.396 79.0894C488.5 79.0894 485.648 80.2208 482.842 82.4837C480.036 84.7465 477.728 87.824 475.918 91.7162C474.198 95.5178 473.338 99.9078 473.338 104.886H478.226C478.226 101.899 478.498 99.2289 479.041 96.8755C479.674 94.5221 480.761 92.6666 482.299 91.3089C483.838 89.9511 485.965 89.2723 488.681 89.2723C490.491 89.2723 491.984 89.5438 493.161 90.0869C494.338 90.5395 495.65 91.3089 497.099 92.395Z"
          fill="black"
        />
        <path
          d="M524.765 80.4471H513.904V142.902H524.765V80.4471ZM543.638 92.395L549.612 83.4341C547.982 81.7143 546.172 80.5829 544.181 80.0398C542.28 79.4062 540.198 79.0894 537.935 79.0894C535.039 79.0894 532.187 80.2208 529.382 82.4837C526.576 84.7465 524.267 87.824 522.457 91.7162C520.737 95.5178 519.877 99.9078 519.877 104.886H524.765C524.765 101.899 525.037 99.2289 525.58 96.8755C526.214 94.5221 527.3 92.6666 528.838 91.3089C530.377 89.9511 532.504 89.2723 535.22 89.2723C537.03 89.2723 538.524 89.5438 539.7 90.0869C540.877 90.5395 542.189 91.3089 543.638 92.395Z"
          fill="black"
        />
        <path
          d="M560.443 54.6504C560.443 56.6417 561.167 58.4067 562.615 59.9455C564.154 61.3937 565.919 62.1178 567.91 62.1178C569.992 62.1178 571.757 61.3937 573.205 59.9455C574.653 58.4067 575.378 56.6417 575.378 54.6504C575.378 52.5685 574.653 50.8035 573.205 49.3553C571.757 47.907 569.992 47.1829 567.91 47.1829C565.919 47.1829 564.154 47.907 562.615 49.3553C561.167 50.8035 560.443 52.5685 560.443 54.6504ZM562.479 80.4471V142.902H573.341V80.4471H562.479Z"
          fill="black"
        />
        <path
          d="M586.207 80.4471V90.63H617.435V80.4471H586.207ZM596.39 58.7235V142.902H607.252V58.7235H596.39Z"
          fill="black"
        />
        <path
          d="M622.898 111.675C622.898 117.92 624.301 123.532 627.107 128.51C630.003 133.398 633.895 137.245 638.783 140.051C643.671 142.857 649.147 144.26 655.212 144.26C661.367 144.26 666.843 142.857 671.64 140.051C676.528 137.245 680.375 133.398 683.181 128.51C686.077 123.532 687.525 117.92 687.525 111.675C687.525 105.339 686.077 99.7267 683.181 94.8389C680.375 89.9511 676.528 86.1043 671.64 83.2983C666.843 80.4923 661.367 79.0894 655.212 79.0894C649.147 79.0894 643.671 80.4923 638.783 83.2983C633.895 86.1043 630.003 89.9511 627.107 94.8389C624.301 99.7267 622.898 105.339 622.898 111.675ZM633.895 111.675C633.895 107.33 634.8 103.483 636.611 100.134C638.512 96.6945 641.046 94.0243 644.214 92.1235C647.473 90.2227 651.138 89.2723 655.212 89.2723C659.285 89.2723 662.905 90.2227 666.073 92.1235C669.332 94.0243 671.866 96.6945 673.677 100.134C675.577 103.483 676.528 107.33 676.528 111.675C676.528 116.019 675.577 119.866 673.677 123.215C671.866 126.564 669.332 129.235 666.073 131.226C662.905 133.127 659.285 134.077 655.212 134.077C651.138 134.077 647.473 133.127 644.214 131.226C641.046 129.235 638.512 126.564 636.611 123.215C634.8 119.866 633.895 116.019 633.895 111.675Z"
          fill="black"
        />
        <path
          d="M709.507 58.7235H752.003V47.8618H709.507V58.7235ZM709.507 96.7397H750.646V85.878H709.507V96.7397ZM703.125 47.8618V142.902H714.666V47.8618H703.125Z"
          fill="black"
        />
        <path
          d="M770.333 54.6504C770.333 56.6417 771.057 58.4067 772.505 59.9455C774.044 61.3937 775.809 62.1178 777.8 62.1178C779.882 62.1178 781.647 61.3937 783.095 59.9455C784.543 58.4067 785.267 56.6417 785.267 54.6504C785.267 52.5685 784.543 50.8035 783.095 49.3553C781.647 47.907 779.882 47.1829 777.8 47.1829C775.809 47.1829 774.044 47.907 772.505 49.3553C771.057 50.8035 770.333 52.5685 770.333 54.6504ZM772.369 80.4471V142.902H783.231V80.4471H772.369Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_309_29">
          <rect width="786" height="182" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ChurritoFi;
